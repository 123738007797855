<template>
    <page title="我的请假" :hasBack="true" :hasPadding="false" textAlign="left">
        <div class="my-leave-page">
            <van-tabs
                v-model:active="currentTabType"
                title-active-color="#07C160"
                title-inactive-color="#666666"
                color="#07C160"
                @change="tabChange"
            >
                <van-tab v-for="item in tabList" :key="item.type" :title="item.title" :name="item.type">
                    <van-pull-refresh v-model="item.refreshing" @refresh="onRefresh">
                        <van-list
                            @load="onLoad"
                            v-model:loading="item.loading"
                            :finished="item.finished"
                            finished-text="已经到底了"
                            :offset="50"
                            :immediate-check="false"
                            v-if="!item.isLoad || item.leaveList.length"
                        >
                            <common-block
                                v-for="leave in item.leaveList"
                                :key="leave.id"
                                class="leave-msg"
                                title="请假信息"
                                @click="viewLeaveDetail(leave.id)"
                            >
                                <leave-des :leave-msg="leave" />
                                <triangle :type="leave.status" />
                            </common-block>
                        </van-list>
                        <empty v-else />
                    </van-pull-refresh>
                </van-tab>
            </van-tabs>
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import Empty from '@/components/empty/Empty';
import CommonBlock from '@/components/commonBlock/CommonBlock';
import LeaveDes from '@/components/leaveDes/LeaveDes';
import Triangle from '@/components/triangle/Triangle';
import { Tab, Tabs, List, PullRefresh } from 'vant';
import { reactive, ref } from 'vue';
import commonRequest from '@/services';
import { useRouter } from 'vue-router';
export default {
    name: 'schoolNotice',
    components: {
        [Page.name]: Page,
        [Empty.name]: Empty,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [PullRefresh.name]: PullRefresh,
        [List.name]: List,
        [CommonBlock.name]: CommonBlock,
        [LeaveDes.name]: LeaveDes,
        [Triangle.name]: Triangle,
    },
    setup() {
        const router = useRouter();
        const tabList = reactive([
            {
                title: '全部',
                type: 0,
                loading: false,
                isLoad: false,
                refreshing: false,
                finished: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '待批假',
                type: 1,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '已批假',
                type: 2,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '已完成',
                type: 3,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '已驳回',
                type: 4,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
            {
                title: '已取消',
                type: 5,
                loading: false,
                refreshing: false,
                finished: false,
                isLoad: false,
                leaveList: [],
                pageNum: 0,
            },
        ]);
        const currentTabType = ref(0);
        const initPageSize = 10;
        const fetchData = () => {
            const activeIndex = tabList.findIndex((item) => item.type === currentTabType.value);

            tabList[activeIndex].pageNum++;
            tabList[activeIndex].loading = true;
            commonRequest({
                action: 11005,
                status: activeIndex,
                pageNum: tabList[activeIndex].pageNum,
                pageSize: initPageSize,
            }).then((res) => {
                tabList[activeIndex].loading = false;
                tabList[activeIndex].isLoad = true;
                tabList[activeIndex].refreshing = false;
                if (res.rows) {
                    tabList[activeIndex].leaveList = tabList[activeIndex].leaveList.concat(res.rows);
                    if (res.rows.length < initPageSize) {
                        tabList[activeIndex].finished = true;
                    }
                }
            });
        };
        const onRefresh = () => {
            const activeIndex = tabList.findIndex((item) => item.type === currentTabType.value);
            tabList[activeIndex].leaveList = [];
            tabList[activeIndex].pageNum = 0;
            tabList[activeIndex].refreshing = true;
            tabList[activeIndex].finished = false;
            tabList[activeIndex].isLoad = false;
            fetchData();
        };
        const onLoad = () => {
            fetchData();
        };
        const tabChange = () => {
            const activeIndex = tabList.findIndex((item) => item.type === currentTabType.value);
            console.log(tabList[activeIndex].isLoad, activeIndex, 'tabChange');
            if (!tabList[activeIndex].isLoad) {
                fetchData();
            }
        };
        fetchData();
        const viewLeaveDetail = (id) => {
            router.push({
                path: '/leaveDetail',
                query: { id: id },
            });
        };
        return {
            tabList,
            currentTabType,
            onRefresh,
            onLoad,
            tabChange,
            viewLeaveDetail,
        };
    },
};
</script>
<style lang="less" scoped>
.my-leave-page {
    height: 100%;
    padding-top: 16px;
    padding-bottom: 20px;
    box-sizing: border-box;
    /deep/ .van-tabs {
        height: 100%;
        display: flex;
        flex-direction: column;
        .van-tabs__wrap {
            padding: 0 15px;
            margin-bottom: 10px;
        }
        .van-tabs__content {
            flex: 1;
            overflow: auto;
            padding: 0 15px;
        }
        .van-tabs__wrap {
            flex-shrink: 0;
        }
        .van-tab__pane {
            height: 100%;
        }
        .van-pull-refresh {
            height: 100%;
        }
        /*.van-tabs__nav--line{*/
        /*    padding-bottom: 5px;*/
        /*}*/
    }
    /deep/ .van-pull-refresh {
        overflow: initial;
    }
}
</style>
